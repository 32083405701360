import React, { useEffect, useRef } from "react";
import CustomSelect from "../select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, CountryCode, DatetimeInput, FileContainer, Info, Input, InputContainer, Label, Line, TextArea } from "./styles";
import { GetIcon } from "../../../icons";
import Checkbox from "../checkbox";
import MultiSelect from "../multiSelect";
import EditorNew from "../editor";
import moment from "moment";
import InfoBoxItem from "./info";
import { projectSettings } from "../../project/brand/project";
import { avathar } from "../../../images";
import { useState } from "react";
import CustomLabel from "./label";
import ErrorLabel from "./error";
import CustomTitle from "./heading";

const FormInput = React.memo((props) => {
  // console.log("input", props.Label, props.name);
  // Initialize translation function for current language
  const { t } = useTranslation();
  const textareaRef = useRef(null);
  useEffect(() => {
    if (textareaRef.current) {
      // Check if textareaRef is not null
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 5}px`; // Set height to scrollHeight
    }
  }, [props.value]); // Dependency array ensures this runs when 'text' changes

  // Create a reference to file input element
  const fileInputRef = useRef(null);
  // Get theme colors from Redux store
  const themeColors = useSelector((state) => state.themeColors);
  const [country, setCountry] = useState(props.countries?.[0] ?? {});
  const [openCountry, setOpenCountry] = useState(false);
  // console.log(props.label,props.value)
  // try {
  switch (props.type) {
    // Render a regular text input
    case "text":
    case "password":
    case "email":
      return (
        <InputContainer className={`${props.dynamicClass ?? ""} ${projectSettings.formInputView}`} animation={props.animation}>
          <CustomLabel name={props.name} label={props.label} required={props.required} sublabel={props.sublabel} error={props.error ?? ""} />
          <InfoBoxItem info={props.info} />
          <Input name={props.name} {...(props.maxLength > 0 ? { maxLength: props.maxLength } : {})} disabled={props.disabled ?? false} autoComplete="on" theme={themeColors} className={`input ${props.value?.toString().length > 0 ? "shrink" : ""}`} placeholder={t(props.placeholder)} type={props.type} value={props.value ?? ""} onChange={(event) => props.onChange(event, props.id, props.type, props.sub)} />
          <ErrorLabel error={props.error} info={props.info} />
        </InputContainer>
      );
    case "number":
      const value = isNaN(props.value) ? "" : props.value;
      const handleKeyDown = (event) => {
        if (event.keyCode === 38 || event.keyCode === 40) {
          // Prevent the default behavior for up and down arrow keys
          console.log("event", "aborted");
          event.preventDefault();
        }
      };
      return (
        <InputContainer className={`${props.dynamicClass ?? ""} ${props.customClass ?? ""}`} animation={props.animation}>
          <CustomLabel name={props.name} label={props.label} required={props.required} sublabel={props.sublabel} value={props.value} error={props.error} />

          <InfoBoxItem info={props.info} />
          <Input
            disabled={props.disabled ?? false}
            onKeyDown={handleKeyDown} // Attach the onKeyDown event handler
            onWheel={(e) => e.target.blur()}
            autoComplete="on"
            theme={themeColors}
            className={`input ${value?.toString().length > 0 ? "shrink" : ""}`}
            placeholder={t(props.placeholder)}
            type={props.type}
            value={value}
            onChange={(event) => props.onChange(event, props.id, props.type, props.sub)}
            min={0}
            maxLength={props.maximum}
            name={props.name}
          />
          <ErrorLabel error={props.error} info={props.info} />
        </InputContainer>
      );
    case "mobilenumber":
      // try {
      const value1 = isNaN(props.value) ? null : props.value;
      const handleKeyDown1 = (event) => {
        if (event.keyCode === 38 || event.keyCode === 40) {
          // Prevent the default behavior for up and down arrow keys
          console.log("event", "aborted");
          event.preventDefault();
        }
      };
      return (
        <InputContainer className={`${props.dynamicClass ?? ""} ${props.customClass ?? ""}`} animation={props.animation}>
          <InfoBoxItem info={props.info} />
          <CustomLabel name={props.name} label={props.label} required={props.required} sublabel={props.sublabel} error={props.error ?? ""} />
          <CountryCode
            onClick={() => {
              setOpenCountry((prev) => !prev);
            }}
          >
            {`${country.flag} +${country.phoneCode}`}
            {openCountry && (
              <div className="options">
                {props.countries.map((country, index) => {
                  return (
                    <React.Fragment key={index}>
                      {index > 0 && <div className="line"></div>}
                      <div
                        className="option"
                        onClick={(e) => {
                          setOpenCountry((prev) => !prev);
                          setCountry(country);
                          props.onChange({ target: { value: value1 } }, props.id, props.type, props.sub, country);
                          e.stopPropagation();
                        }}
                      >{`${country.flag} +${country.phoneCode} - ${country.title}`}</div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
          </CountryCode>
          <Input
            disabled={props.disabled ?? false}
            onKeyDown={handleKeyDown1} // Attach the onKeyDown event handler
            onWheel={(e) => e.target.blur()}
            autoComplete="on"
            theme={themeColors}
            className={`input phone${country.phoneCode.toString().length} ${value1?.toString().length > 0 ? "" : ""}`}
            placeholder={`WhatsApp Number`}
            type="number"
            value={value1}
            onChange={(event) => props.onChange(event, props.id, props.type, props.sub, country)}
            min={0}
            max={Math.pow(10, country.PhoneNumberLength) - 1}
            name={props.name}
          />
          <ErrorLabel error={props.error} info={props.info} />
        </InputContainer>
      );
    // } catch (err) {
    //   console.log(err);
    //   return <div>{props.label}</div>;
    // }
    // Render a time input with time picker
    case "time":
      let userFriendlyTime = typeof props.value === "undefined" || props.value === null ? null : props.value.length > 0 ? new Date(props.value) : null;
      return (
        <InputContainer className={`${props.dynamicClass ?? ""}`}>
          <CustomLabel name={props.name} label={props.label} required={props.required} sublabel={props.sublabel} error={props.error ?? ""} />
          <InfoBoxItem info={props.info} />
          <DatetimeInput name={props.name} theme={themeColors} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" selected={userFriendlyTime} dateFormat="h:mm aa" className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholderText={t(props.label)} type={props.type} onChange={(event) => props.onChange(event, props.id, props.type)} />

          <ErrorLabel error={props.error} info={props.info} />
        </InputContainer>
      );
    // Render a date input with date picker
    case "date":
      let userFriendlyDate = typeof props.value === "undefined" || props.value === null ? null : props.value.length > 0 ? new Date(props.value) : null;
      //console.log("props.value", props.value, userFriendlyDate);
      return (
        <InputContainer className={`${props.dynamicClass ?? ""} ${props.customClass ?? ""}`}>
          <CustomLabel name={props.name} label={props.label} required={props.required} sublabel={props.sublabel} error={props.error ?? ""} /> <InfoBoxItem info={props.info} />
          <DatetimeInput name={props.name} showYearDropdown yearDropdownItemNumber={70} minDate={props.minDate ?? moment().toDate()} maxDate={props.maxDate ?? moment().add(1, "year").toDate()} dateFormat={"yyyy-MM-dd"} theme={themeColors} className={`input ${props.value?.length > 0 ? "shrink" : ""}`} placeholderText={t(props.label)} type={props.type} value={userFriendlyDate} selected={userFriendlyDate} onChange={(event) => props.onChange(event, props.id, props.type)} />
          <ErrorLabel error={props.error} info={props.info} />
        </InputContainer>
      );
    // Render a datetime input with date and time pickers
    case "datetime":
      let userFriendlyDateTime = typeof props.value === "undefined" || props.value === null ? null : props.value.length > 0 ? new Date(props.value) : null;

      return (
        <InputContainer className={`${props.dynamicClass ?? ""}`}>
          <CustomLabel name={props.name} label={props.label} required={props.required} sublabel={props.sublabel} error={props.error ?? ""} />
          <InfoBoxItem info={props.info} />
          <DatetimeInput name={props.name} showYearDropdown yearDropdownItemNumber={70} minDate={props.minDate ?? moment().toDate()} maxDate={props.maxDate ?? moment().add(1, "year").toDate()} theme={themeColors} showTimeSelect timeIntervals={1} className={`input ${props.value?.length > 0 ? "shrink" : ""}`} placeholderText={t(props.label)} type={props.type} value={userFriendlyDateTime} selected={userFriendlyDateTime} dateFormat={"yyyy-MM-dd hh:mm a"} onChange={(event) => props.onChange(event, props.id, props.type)} />

          <ErrorLabel error={props.error} info={props.info} />
        </InputContainer>
      );
    // Render a file and image
    case "image":
    case "file":
      // function formatSize(sizeInBytes) {
      //   if (sizeInBytes < 1024) {
      //     return sizeInBytes + " bytes";
      //   } else if (sizeInBytes < 1024 * 1024) {
      //     return (sizeInBytes / 1024).toFixed(2) + " KB";
      //   } else {
      //     return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
      //   }
      // }
      //const size = formatSize(props.value[0] ? props.value[0].size : 0);
      const handleButtonClick = () => {
        fileInputRef.current.click();
      };
      const image = props.formValues["old_" + props.name];
      return (
        <FileContainer className={`${props.dynamicClass ?? ""}`} theme={themeColors}>
          <CustomLabel name={props.name} className={`${props.dynamicClass ?? ""}`} label={props.label} required={props.required} sublabel={props.sublabel} error={props.error ?? ""} />
          <div>
            <div>
              {image ? (
                <img
                  alt="upload"
                  className="contain"
                  onClick={(e) => {
                    // onClick({ src: e.target.src.replace("/thumbnail", "") });
                  }}
                  src={image ? process.env.REACT_APP_CDN + image : avathar}
                />
              ) : (
                <img
                  alt="upload"
                  className="contain"
                  onClick={(e) => {
                    // onClick({ src: e.target.src.replace("/thumbnail", "") });
                  }}
                  src={avathar}
                />
              )}
            </div>
            <div>
              <InfoBoxItem info={props.info} />
              <CustomTitle line={false} description="File size: Up to 5MB <br />Optimal dimensions: 600x280px<br /> Supported file types: JPG, JPEG, PNG, GIF, WEBP"></CustomTitle>
              <FormInput name={props.name} customClass={"secondary"} onChange={handleButtonClick} value="Choose Photo" type="button"></FormInput>
              {/* <button onClick={handleButtonClick}>Upload</button> */}
              <Input ref={fileInputRef} style={{ display: "none" }} theme={themeColors} accept={props.type === "image" ? `image/*` : ``} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholder={t(props.placeholder)} type={`file`} onChange={(event) => props.onChange(event, props.id, props.type)} />
              <ErrorLabel error={props.error} info={props.info} />
            </div>
          </div>
        </FileContainer>
      );
    // Render a textarea
    case "textarea":
      return (
        <InputContainer className={`textarea ${props.dynamicClass ?? ""}`}>
          <InfoBoxItem info={props.info} />
          <CustomLabel label={props.label} required={props.required} sublabel={props.sublabel} error={props.error ?? ""} />
          <TextArea name={props.name} ref={textareaRef} theme={themeColors} className={`input ${props?.value?.length > 0 ? "shrink" : ""}  ${props.size ?? ""}`} placeholder={t(props.placeholder)} value={props.value} onChange={(event) => props.onChange(event, props.id)} />
          <ErrorLabel error={props.error} info={props.info} />
        </InputContainer>
      );
    case "htmleditor":
      return <EditorNew dynamicClass={`${props.dynamicClass ?? ""}`} key={props.id} type={props.type} placeholder={props.placeholder} value={props.value} id={props.id} onChange={props.onChange}></EditorNew>;
    // Render a submit button
    case "submit":
      return (
        <Button theme={themeColors} className="submit" disabled={props.disabled} type={props.type} onClick={props.onChange}>
          {props.value}
        </Button>
      );
    case "button":
      return (
        <Button theme={themeColors} className={props.customClass} disabled={props.disabled} type={props.type} onClick={props.onChange}>
          {props.icon ? <GetIcon icon={props.icon}></GetIcon> : null}
          <span> {props.value}</span>
        </Button>
      );
    // Render a close button
    case "close":
      return (
        <Button disabled={props.disabled ?? false} theme={themeColors} className={"close " + props.className} type={props.type} onClick={props.onChange}>
          {props.value}
        </Button>
      );
    // Render a cehckbox
    case "checkbox":
      return (
        <InputContainer className={`checkbox ${props.dynamicClass ?? ""} ${props.customClass ?? ""} `}>
          <InfoBoxItem info={props.info} />
          <Label className="checkbox">
            <Checkbox
              name={props.name}
              theme={themeColors}
              label={t(props.placeholder)}
              className={"checkbox " + props.customClass}
              type={props.type}
              checked={props.value ?? false}
              sublabel={props.sublabel}
              onChange={(event) => {
                console.log(event.target.checked === false ? false : true);
                props.onChange(event.target.checked === false ? false : true, props.id, props.type);
              }}
            ></Checkbox>
            {/* <span dangerouslySetInnerHTML={{ __html: t(props.placeholder) }}></span> */}
          </Label>
        </InputContainer>
      );
    // Render a select box
    case "select":
      return <CustomSelect theme={themeColors} {...props} name={props.id} selected={props.value} onSelect={props.onChange}></CustomSelect>;
    case "multiSelect":
      return <MultiSelect theme={themeColors} {...props} name={props.id} selected={props.value} onSelect={props.onChange}></MultiSelect>;
    case "info":
      return (
        <Info className={` ${props.dynamicClass}`}>
          <GetIcon icon={"info"}></GetIcon> <span dangerouslySetInnerHTML={{ __html: props.content }}></span>
        </Info>
      );
    case "html":
      return <Info className={` ${props.dynamicClass}`}>{props.content}</Info>;
    case "line":
      return <Line className={`${props.dynamicClass}`}></Line>;
    case "title":
      return <CustomTitle margin={false} line={true} icon={props.icon} title={props.title} description={props.info}></CustomTitle>;
    case "hidden":
      return <Input disabled={props.disabled ?? false} autoComplete="on" theme={themeColors} className={`input ${props.value?.toString().length > 0 ? "shrink" : ""}`} placeholder={t(props.placeholder)} type={props.type} value={props.value} onChange={(event) => props.onChange(event, props.id, props.type, props.sub)} />;
    default:
      return <></>;
  }
  // } catch (err) {
  //   console.log(err);
  //   return <></>;
  // }
});
export default FormInput;
